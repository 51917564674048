<template>
  <div id="#app">
    <router-view></router-view>
  </div>
</template>

<script>
import Login from "./components/Login/Login.vue";
export default {
  name: "App",
  components: {
    Login,
  },
};
</script>

<style lang="less">
body {
  background-color: rgb(243, 243, 243);
}
#app {
  background-color: rgb(168, 167, 165);
}
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
