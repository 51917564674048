<template>
  <div class="StoreLocator">
    <div
      style="
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
      "
    >
      <div style="display: flex; flex-direction: row; margin-left: 100px">
        <el-radio-group
          style="display: flex; flex-direction: row"
          v-model="twoSelone"
          @input="selArea_or_Category"
        >
          <el-radio-button :label="true">按地区</el-radio-button>
          <el-radio-button :label="false">按类型</el-radio-button>
        </el-radio-group>

        <el-select
          v-if="twoSelone"
          v-model="clasearch"
          placeholder="请选择分类"
          style="margin-left: 40px; width: 150px"
          @change="changecla"
        >
          <el-option
            v-for="item in classify"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>

        <el-select
          v-if="twoSelone1"
          v-model="clasearch1"
          placeholder="请选择分类"
          style="margin-left: 40px; width: 150px"
          @change="changecla1"
        >
          <el-option
            v-for="item in Storeclalist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <el-dropdown
        trigger="hover"
        placement="bottom-start"
        style="margin-left: 40px"
      >
        <span class="el-dropdown-link">
          更多操作<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <el-button
              icon="el-icon-search"
              @click="gotosearch()"
              type="text"
              style="color: #bfbfbf"
            >
              搜索店铺
            </el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button icon="el-icon-edit" @click="addStore()" type="text">
              添加店铺
            </el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button
              icon="el-icon-delete"
              @click="clear()"
              type="text"
              style="color: red"
            >
              清除缓存
            </el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div
      style="
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="width: 90%; height: 100%">
        <el-table :data="storelist" width="100%" height="100%" border="true">
          <!-- <el-table-column
            prop="categoryId"
            label="所属类别"
            width="100"
          ></el-table-column> -->
          <el-table-column label="店铺图片" width="100" align="center">
            <template slot-scope="scope">
              <img
                :src="scope.row.image"
                alt=""
                style="width: 30px; height: 30px"
              />
            </template>
          </el-table-column>
          <el-table-column label="店铺二维码" width="100" align="center">
            <template slot-scope="scope">
              <img
                :src="scope.row.qrCodeImage"
                alt=""
                style="width: 30px; height: 30px"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="店名"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column label="营业时间" width="150" align="center">
            <template v-slot="scope">
              {{ scope.row.openingTime }}--{{ scope.row.closingTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="修改时间"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="createUser"
            label="创建人"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="updateUser"
            label="修改人"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="ModStore(scope.row)"
                >修改</el-button
              >
              <el-button size="mini" type="danger" @click="DelStore(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      title="确认删除"
      :visible.sync="deldis"
      width="30%"
      center
      :append-to-body="AppendToBody"
    >
      <div style="width: 100%; display: flex; justify-content: center">
        确定要删除"<span>{{ dstore.name }} </span>"的信息吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del_sure()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="添加店铺信息"
      :visible.sync="addDis"
      width="35%"
      center
      :append-to-body="AppendToBody"
    >
      <el-form
        :model="store"
        label-width="120px"
        style="height: 500px; overflow-y: scroll"
      >
        <el-form-item label="店铺名称:">
          <el-input v-model="store.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选择区域:">
          <el-select v-model="selectedarea" placeholder="请选择">
            <el-option
              v-for="(item, index) in classify"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择类型:">
          <el-select v-model="selectedarea1" placeholder="请选择">
            <el-option
              v-for="(item, index) in Storeclalist"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="店铺图片1:">
          <el-image
            :src="selstoreIge1"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="handleFileChange1"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取文件
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="点餐二维码:">
          <el-image
            :src="selstoreIge2"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="handleFileChange2"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
          <!-- 这里可以添加el-upload组件用于上传二维码，但在此省略 -->
        </el-form-item>

        <!-- 店铺链接 -->
        <el-form-item label="店铺链接:">
          <el-input v-model="store.jump_url" placeholder="店铺链接"></el-input>
        </el-form-item>

        <!-- 店铺描述 -->
        <el-form-item label="店铺描述:">
          <el-input
            v-model="store.description"
            type="textarea"
            placeholder="请输入店铺描述"
          ></el-input>
        </el-form-item>

        <!-- 店铺开门时间 -->
        <el-form-item label="店铺开门时间：" style="width: 400px">
          <el-select
            v-model="openhour"
            placeholder="请选择小时"
            style="width: 100px"
          >
            <el-option
              v-for="hour in hours"
              :key="hour"
              :label="hour"
              :value="hour"
            ></el-option>
          </el-select>
          <span style="font-weight: bolder"> : </span>
          <el-select
            v-model="openmin"
            placeholder="请选择分钟"
            style="width: 100px"
          >
            <el-option
              v-for="min in mins"
              :key="min"
              :label="min"
              :value="min"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 店铺关门时间 -->
        <el-form-item label="店铺关门时间：" style="width: 400px">
          <el-select
            v-model="closehour"
            placeholder="请选择小时"
            style="width: 100px"
          >
            <el-option
              v-for="hour in hours"
              :key="hour"
              :label="hour"
              :value="hour"
            ></el-option>
          </el-select>
          <span style="font-weight: bolder"> : </span>
          <el-select
            v-model="closemin"
            placeholder="请选择分钟"
            style="width: 100px"
          >
            <el-option
              v-for="min in mins"
              :key="min"
              :label="min"
              :value="min"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="width: 100%; display: flex; justify-content: center">
        <el-button type="primary" @click="sure()">添加</el-button>
      </div>
    </el-dialog>

    <!-- 修改店铺 -->
    <el-dialog
      title="修改店铺信息"
      :visible.sync="modDis"
      width="35%"
      center
      :append-to-body="AppendToBody"
    >
      <el-form
        :model="store"
        label-width="120px"
        style="height: 500px; overflow-y: scroll"
      >
        <el-form-item label="店铺名称:">
          <el-input v-model="dstore.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选择区域:">
          <el-select v-model="selectedarea" placeholder="请选择">
            <el-option
              v-for="(item, index) in classify"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择类型:">
          <el-select v-model="selectedarea1" placeholder="请选择">
            <el-option
              v-for="(item, index) in Storeclalist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="店铺图片1:">
          <el-image
            :src="selstoreIge1"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="handleFileChange1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="点餐二维码:">
          <el-image
            :src="selstoreIge2"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="handleFileChange2"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取文件
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
          <!-- 这里可以添加el-upload组件用于上传二维码，但在此省略 -->
        </el-form-item>

        <!-- 店铺链接 -->
        <el-form-item label="店铺链接:">
          <el-input v-model="dstore.jumpUrl" placeholder="店铺链接"></el-input>
        </el-form-item>

        <!-- 店铺描述 -->
        <el-form-item label="店铺描述:">
          <el-input
            v-model="dstore.description"
            type="textarea"
            placeholder="请输入店铺描述"
          ></el-input>
        </el-form-item>

        <!-- 店铺开门时间 -->
        <el-form-item label="店铺开门时间：" style="width: 400px">
          <el-select
            v-model="openhour"
            placeholder="请选择小时"
            style="width: 100px"
          >
            <el-option
              v-for="hour in hours"
              :key="hour"
              :label="hour"
              :value="hour"
            ></el-option>
          </el-select>
          <span style="font-weight: bolder"> : </span>
          <el-select
            v-model="openmin"
            placeholder="请选择分钟"
            style="width: 100px"
          >
            <el-option
              v-for="min in mins"
              :key="min"
              :label="min"
              :value="min"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 店铺关门时间 -->
        <el-form-item label="店铺关门时间：" style="width: 400px">
          <el-select
            v-model="closehour"
            placeholder="请选择小时"
            style="width: 100px"
          >
            <el-option
              v-for="hour in hours"
              :key="hour"
              :label="hour"
              :value="hour"
            ></el-option>
          </el-select>
          <span style="font-weight: bolder"> : </span>
          <el-select
            v-model="closemin"
            placeholder="请选择分钟"
            style="width: 100px"
          >
            <el-option
              v-for="min in mins"
              :key="min"
              :label="min"
              :value="min"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="width: 100%; display: flex; justify-content: center">
        <el-button type="primary" @click="mod_sure()">修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Classify from "../Classify/Classify.vue";
import bus from "../eventBus.js";
import axios from "axios";
export default Vue.extend({
  mounted() {},
  components: {},
  data() {
    return {
      th: null,
      showmsg: "none",
      tipmsg: "",
      token: "",
      AppendToBody: true,
      addDis: false,
      deldis: false,
      modDis: false,
      modDis: false,
      selectedarea: "",
      selectedarea1: "",
      openhour: "00",
      openmin: "00",
      closehour: "23",
      closemin: "59",
      dstore: "",
      selstoreIge1: null,
      selstoreIge2: null,
      showFileInput1: false,
      search: "",
      clasearch: "",
      clasearch1: "",
      classify: [],
      Storeclalist: [],
      m_find: "11111",
      hours: Array.from({ length: 24 }, (_, index) =>
        String(index).padStart(2, "0")
      ),
      mins: Array.from({ length: 60 }, (_, index) =>
        index.toString().padStart(2, "0")
      ),
      storelist: [],
      search_store: {
        sel: 0,
        txt: [],
        search: "",
      },
      store: {
        id: "",
        name: "",
        image: "",
        qr_code_image: "",
        store_zone: "",
        opening_time: "",
        closeing_time: "",
        create_time: "",
        update_time: "",
        create_user: "",
        update_user: "",
        jump_url: "",
        description: "",
      },
      twoSelone: true,
      twoSelone1: false,
    };
  },
  computed: {},
  created() {
    let th = this;
    this.token = localStorage.getItem("token");
    this.getclalist();
    this.getstorecla();
  },
  destroyed() {
    bus.$emit("share2", this.search_store);
  },
  methods: {
    clear() {
      axios
        .post("/api/admin/common/clearShopCache", null, {
          headers: {
            "Content-Type": "application/json",
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.data,
              type: "success",
            });
          } else {
            this.$message.error("清除失败");
          }
        });
    },
    // 图片上传接口
    async filePut(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },

    getclalist() {
      let config = {
        params: {
          pageNo: 1,
          pageSize: 200,
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/category/page", config).then((res) => {
        if (res.data.code == 1) {
          this.classify = res.data.data.records;
          if (this.classify.length > 0) {
            this.clasearch = this.classify[0].id;
            this.getstore(this.classify[0].id);
          }
        }
      });
    },
    getstorecla() {
      let th = this;
      this.Storeclalist = [];
      let config = {
        params: {
          pageNo: 1,
          pageSize: 200,
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/store-classification/page", config).then((res) => {
        if (res.data.code == 1) {
          this.Storeclalist = res.data.data.records;
          this.Storeclalist.reverse();
          console.log(this.Storeclalist);
          if (this.Storeclalist.length > 0) {
            this.clasearch1 = this.Storeclalist[0].id;
            console.log(this.clasearch1);
          }
        }
      });
    },
    getstore(claId) {
      console.log("getstore");
      const categoryId = claId;
      const config = {
        headers: {
          token: this.token,
        },
      };
      axios
        .get(`/api/admin/store/list/category/${categoryId}`, config)
        .then((res) => {
          if (res.data.data.length > 0) {
            let storelist = res.data.data;
            this.storelist = storelist;
          }
        })
        .catch((err) => {});
    },
    //根据类型id选择店铺
    getstore1(claId) {
      console.log("getstore1");
      const categoryId = claId;
      const config = {
        headers: {
          token: this.token,
        },
      };
      axios
        .get(`/api/admin/store/list/classification/${categoryId}`, config)
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.data.length > 0) {
              this.storelist = res.data.data;
            }
          }
        })
        .catch((err) => {});
    },
    selArea_or_Category(e) {
      if (e) {
        this.twoSelone = e;
        this.twoSelone1 = !e;
        this.getstore(this.clasearch);
      } else {
        this.twoSelone = e;
        this.twoSelone1 = !e;
        this.getstore1(this.clasearch1);
      }
    },
    gotosearch() {
      this.$router.push({
        path: "/search",
      });
    },
    searchstore1() {
      const config = {
        params: {
          name: this.search,
          openingTime: "",
          closingTime: "",
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/store/find", config).then((res) => {
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.classify.length; j++) {
            if (data[i].categoryId == this.classify[j].id) {
              data[i].categoryId = this.classify[j].name;
              break;
            }
          }
        }
        let search = {
          sel: 1,
          txt: data,
        };
        this.search_store.sel = 1;
        this.search_store.txt = data;
        this.search_store.search = this.search;
        bus.$emit("share1", search);
      });
    },
    //根据位置找店铺
    changecla(e) {
      this.storelist = [];
      this.getstore(e);
    },
    //根据类型找店铺
    changecla1(e) {
      this.storelist = [];
      this.getstore1(e);
    },

    Storenull() {
      for (let key in this.store) {
        if (this.store.hasOwnProperty(key)) {
          // 使用hasOwnProperty来检查属性是否是对象自身的（而非从原型链上继承的）
          this.store[key] = "";
        }
      }
      this.openhour = "00";
      this.openmin = "00";
      this.closehour = "23";
      this.closemin = "59";
      this.selstoreIge1 = null;
      this.selstoreIge2 = null;
      this.selectedarea = this.classify[0].name;
      // 获取文件输入框元素
      const fileInput = document.querySelectorAll('input[type="file"]');
      for (let i = 0; i < fileInput.length; i++) {
        // 清空文件输入框的值
        fileInput[i].value = "";
      }
    },
    Cliarea(e) {
      this.selectedarea = e.name;
      console.log(e);
      //跳转位置
      let storelist = document.querySelectorAll(".Store_right ul li");
      let store = "";
      for (let i = 0; i < storelist.length; i++) {
        if (
          storelist[i].querySelector("span:first-child").innerText == e.name
        ) {
          store = storelist[i];
          break;
        }
      }
      if (store) {
        store.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      } else {
      }
    },

    DelStore(store) {
      this.deldis = true;
      this.dstore = store;
    },
    //店铺删除接口
    del_sure() {
      this.deldis = false;
      let storeids = "";
      storeids += this.dstore.id;
      let config = {
        params: {
          storeIds: storeids,
        },
        headers: {
          token: this.token,
        },
      };
      axios
        .delete("/api/admin/store/list", config)
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            for (let i = 0; i < this.classify[0].length; i++) {
              if (this.clasearch == this.classify[i].id) {
                this.getstore(this.classify[i]);
                console.log("this.store");
                break;
              }
            }
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
    },

    ModStore(store) {
      this.modDis = true;
      this.dstore = store;
      this.selectedarea = this.dstore.categoryId;
      this.selectedarea1 = this.dstore.storeClassificationId;
      this.selstoreIge1 = this.dstore.image;
      this.selstoreIge2 = this.dstore.qrCodeImage;
      let part1 = this.dstore.openingTime.split(":");
      let part2 = this.dstore.closingTime.split(":");
      this.openhour = part1[0];
      this.openmin = part1[1];
      this.closehour = part2[0];
      this.closemin = part2[1];
    },
    //修改店铺信息接口
    mod_sure() {
      this.dstore.categoryId = this.selectedarea;
      this.dstore.storeClassificationId = this.selectedarea1;
      this.dstore.image = this.selstoreIge1;
      this.dstore.qrCodeImage = this.selstoreIge2;
      this.dstore.openingTime = this.openhour + ":" + this.openmin + ":" + "00";
      this.dstore.closingTime =
        this.closehour + ":" + this.closemin + ":" + "00";
      console.log(this.dstore);
      axios
        .put(
          "/api/admin/store/list",
          {
            id: this.dstore.id,
            name: this.dstore.name,
            image: this.dstore.image,
            qrCodeImage: this.dstore.qrCodeImage,
            description: this.dstore.description,
            jumpUrl: this.dstore.jumpUrl,
            userRating: this.dstore.userRating,
            ratingUsersCount: this.dstore.ratingUsersCount,
            storeRating: this.dstore.storeRating,
            categoryId: this.dstore.categoryId,
            storeClassificationId: this.dstore.storeClassificationId,
            openingTime: this.dstore.openingTime,
            closingTime: this.dstore.closingTime,
            createTime: "",
            updateTime: "",
            createUser: 0,
            updateUser: 0,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            if (this.twoSelone) {
              this.getstore(this.clasearch);
            } else {
              this.getstore1(this.clasearch1);
            }
          }

          this.storelist = [];
        })
        .catch((err) => {
          this.$message.error("修改失败");
        });
      this.modDis = false;
      this.Storenull();
    },
    //--------------------
    async handleFileChange1(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.selstoreIge1 = img.data;
    },
    async handleFileChange2(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.selstoreIge2 = img.data;
    },
    addStore() {
      this.selectedarea = this.classify[0].name;
      this.selectedarea1 = this.Storeclalist[0].name;
      this.addDis = true;
    },
    sure() {
      let selectid = 0;
      for (let i = 0; i < this.classify.length; i++) {
        if (this.selectedarea == this.classify[i].name) {
          selectid = this.classify[i].id;
          break;
        }
      }
      let selectid1 = 0;
      for (let i = 0; i < this.Storeclalist.length; i++) {
        if (this.selectedarea1 == this.Storeclalist[i].name) {
          selectid1 = this.Storeclalist[i].id;
          break;
        }
      }
      axios
        .post(
          "/api/admin/store",
          {
            id: "",
            name: this.store.name,
            image: this.selstoreIge1,
            qrCodeImage: this.selstoreIge2,
            description: this.store.description,
            jumpUrl: this.store.jump_url,
            categoryId: selectid,
            storeClassificationId: selectid1,
            openingTime: this.openhour + ":" + this.openmin + ":" + "00",
            closingTime: this.closehour + ":" + this.closemin + ":" + "00",
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.storelist = [];
            if (this.twoSelone) {
              this.getstore(this.clasearch);
            } else {
              this.getstore1(this.clasearch1);
            }
          }
        })
        .catch((err) => {
          this.$message.error("添加失败");
        });
      this.addDis = false;
      this.selstoreIge1 = "";
      this.searchstore2 = "";
      this.Storenull();
    },
    handleLiClick() {
      alert("Li element clicked!");
    },
  },
  watch: {},
});
</script>
<style scoped>
.flrow {
  display: flex;
  flex-direction: column;
}
.StoreLocator {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.store {
  position: relative;
  top: 50px;
  left: 50%;
  margin-left: -600px;
  width: 1200px;
  height: 700px;
}
::-webkit-scrollbar {
  display: none;
}
</style>
