<template>
  <div class="search">
    <div
      style="
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        padding-left: 30px;
      "
    >
      <el-page-header @back="back()" content="搜索"> </el-page-header>
    </div>
    <div
      style="
        width: 100%;
        height: auto;
        margin-top: 5px;
        display: flex;
        justify-content: center;
      "
    >
      <div style="width: 50%; height: 100%">
        <el-input
          placeholder="请输入内容"
          v-model="searchtxt"
          class="input-with-select"
        >
          <el-button
            type="info"
            slot="append"
            icon="el-icon-search"
            @click="search()"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div
      style="
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="width: 90%; height: 100%">
        <el-table :data="storelist" style="width: 100%" height="100%">
          <el-table-column
            prop="name"
            label="店名"
            width="180"
            align="center"
          ></el-table-column>
          <el-table-column label="图片" width="120" align="center"
            ><template v-slot="scope">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.image"
                fit="contain"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="位置" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.categoryId }}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.storeClassificationId }}</span>
            </template>
          </el-table-column>
          <el-table-column label="营业时间" width="200" align="center">
            <template slot-scope="scope">
              <span
                >{{ scope.row.openingTime }}--{{ scope.row.closingTime }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="200" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.createTime }}</span
                ><br />
                <span>{{ scope.row.createUser }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" width="200" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.updateTime }}</span
                ><br />
                <span>{{ scope.row.updateUser }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="modstore(scope.row)"
                >修改</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deletestore(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 修改店铺 -->
    <el-dialog
      title="修改店铺信息"
      :visible.sync="m_dis"
      width="35%"
      append-to-body="true"
      center
    >
      <el-form
        :model="m_store"
        label-width="120px"
        style="height: 500px; overflow-y: scroll"
      >
        <el-form-item label="店铺名称:">
          <el-input v-model="m_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选择区域:">
          <el-select v-model="m_cla" placeholder="请选择">
            <el-option
              v-for="(item, index) in classify"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择区域:">
          <el-select v-model="m_category" placeholder="请选择">
            <el-option
              v-for="(item, index) in categoryList"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="店铺图片1:">
          <el-image
            :src="m_image"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="image1"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取文件
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="点餐二维码:">
          <el-image
            :src="m_code"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="image2"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取文件
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="店铺链接:">
          <el-input v-model="m_jumpUrl" placeholder="店铺链接"></el-input>
        </el-form-item>
        <el-form-item label="店铺描述:">
          <el-input
            v-model="m_description"
            type="textarea"
            placeholder="请输入店铺描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺开门时间：" style="width: 400px">
          <el-select
            v-model="m_openhour"
            placeholder="请选择小时"
            style="width: 100px"
          >
            <el-option
              v-for="hour in hours"
              :key="hour"
              :label="hour"
              :value="hour"
            ></el-option>
          </el-select>
          <span style="font-weight: bolder"> : </span>
          <el-select
            v-model="m_openmin"
            placeholder="请选择分钟"
            style="width: 100px"
          >
            <el-option
              v-for="min in mins"
              :key="min"
              :label="min"
              :value="min"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 店铺关门时间 -->
        <el-form-item label="店铺关门时间：" style="width: 400px">
          <el-select
            v-model="m_closhour"
            placeholder="请选择小时"
            style="width: 100px"
          >
            <el-option
              v-for="hour in hours"
              :key="hour"
              :label="hour"
              :value="hour"
            ></el-option>
          </el-select>
          <span style="font-weight: bolder"> : </span>
          <el-select
            v-model="m_closmin"
            placeholder="请选择分钟"
            style="width: 100px"
          >
            <el-option
              v-for="min in mins"
              :key="min"
              :label="min"
              :value="min"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="width: 100%; display: flex; justify-content: center">
        <el-button type="primary" @click="mod_sure()">修改</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="删除确认"
      :visible.sync="d_dis"
      width="30%"
      append-to-body="true"
      center
    >
      <div style="display: flex; justify-content: center">
        确定要删除“{{ d_store.name }}”吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del_sure()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import bus from "../eventBus.js";
import { config } from "@/router/index.js";
import { Message } from "element-ui";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      s_msg: [],
      totalstore: [],
      classify: [],
      categoryList: [],
      storelist: [],
      pagelist: [],
      now_page: "1",
      m_store: "",
      m_name: "",
      m_cla: "",
      m_category: "",
      m_image: "",
      m_code: "",
      m_openhour: "",
      m_openmin: "",
      m_closhour: "",
      m_closmin: "",
      m_jumpUrl: "",
      m_description: "",
      hours: Array.from({ length: 24 }, (_, index) =>
        String(index).padStart(2, "0")
      ),
      mins: Array.from({ length: 60 }, (_, index) =>
        index.toString().padStart(2, "0")
      ),
      d_store: "",
      sh_dis: "none",
      m_dis: false,
      d_dis: false,
      clalist: [],
      ch_search: "",
      searchtxt: "",
    };
  },
  computed: {},
  created() {
    this.token = localStorage.getItem("token");
    this.getCategorylist();
  },
  methods: {
    async filePut(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },
    getCategorylist() {
      let th = this;
      let config = {
        params: {
          pageNo: 1,
          pageSize: 200,
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/category/page", config).then((res) => {
        this.classify = res.data.data.records;
      });
      axios.get("/api/admin/store-classification/page", config).then((res) => {
        this.categoryList = res.data.data.records;
      });
    },
    changepage() {
      this.storelist = [];
      console.log(this.now_page);
      let n =
        this.now_page * 5 > this.totalstore.length
          ? this.totalstore.length
          : this.now_page * 5;
      for (let i = (this.now_page - 1) * 5; i < n; i++) {
        this.storelist.push(this.totalstore[i]);
      }
    },
    backpage() {
      if (this.now_page == 1) {
      } else {
        this.now_page = this.now_page - 1;
        this.changepage();
      }
    },
    nextpage() {
      if (this.now_page >= Math.ceil(this.totalstore.length / 5)) {
      } else {
        this.now_page = this.now_page + 1;
        this.storelist = [];
        this.changepage();
      }
    },
    destroysearch() {
      bus.$off("share2");
    },

    search() {
      const config = {
        params: {
          name: this.searchtxt,
          openingTime: "",
          closingTime: "",
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/store/find", config).then((res) => {
        if (res.data.code == 1) {
          this.storelist = [];
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.classify.length; j++) {
              if (data[i].categoryId == this.classify[j].id) {
                data[i].categoryId = this.classify[j].name;
                break;
              }
            }
          }
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.categoryList.length; j++) {
              if (data[i].storeClassificationId == this.categoryList[j].id) {
                data[i].storeClassificationId = this.categoryList[j].name;
                break;
              }
            }
          }
          this.storelist = data;
        }
      });
    },

    async image1(event) {
      const file = event.raw;
      let img = await this.filePut(file, this.selstoreIge1);
      this.m_image = img.data;
    },
    async image2(event) {
      const file = event.raw;
      let img = await this.filePut(file, this.selstoreIge1);
      this.m_code = img.data;
    },
    modstore(e) {
      this.m_store = e;
      this.m_name = e.name;
      this.m_cla = e.categoryId;
      this.m_category = e.storeClassificationId;
      this.m_image = e.image;
      this.m_code = e.qrCodeImage;
      this.m_description = e.description;
      let part1 = e.openingTime.split(":");
      let part2 = e.closingTime.split(":");
      this.m_jumpUrl = e.jumpUrl;
      this.m_openhour = part1[0];
      this.m_openmin = part1[1];
      this.m_closhour = part2[0];
      this.m_closmin = part2[1];
      this.m_dis = true;
    },
    mod_sure() {
      let ot = this.m_openhour + ":" + this.m_openmin + ":" + "00";
      let ct = this.m_closhour + ":" + this.m_closmin + ":" + "00";
      for (let i = 0; i < this.classify.length; i++) {
        if (this.m_cla == this.classify[i].name) {
          this.m_cla = this.classify[i].id;
          break;
        }
      }
      for (let i = 0; i < this.categoryList.length; i++) {
        if (this.m_category == this.categoryList[i].name) {
          this.m_category = this.categoryList[i].id;
          break;
        }
      }
      axios
        .put(
          "/api/admin/store/list",
          {
            id: this.m_store.id,
            name: this.m_name,
            image: this.m_image,
            qrCodeImage: this.m_code,
            description: this.m_description,
            userRating: this.m_store.userRating,
            ratingUsersCount: this.m_store.ratingUsersCount,
            storeRating: this.m_store.storeRating,
            categoryId: this.m_cla,
            storeClassificationId: this.m_category,
            openingTime: ot,
            closingTime: ct,
            createTime: "",
            updateTime: "",
            createUser: 0,
            updateUser: 0,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.search();
          } else {
            this.$message.error("修改失败");
          }
        });
      this.m_dis = false;
    },
    deletestore(e) {
      console.log(e);
      this.d_store = e;
      this.d_dis = true;
    },
    del_sure() {
      let config = {
        params: {
          storeIds: this.d_store.id,
        },
        headers: {
          token: this.token,
        },
      };
      axios
        .delete("/api/admin/store/list", config)
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.search();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
      this.d_dis = false;
    },
    back() {
      this.$router.go(-1);
    },
  },
  watch: {},

  // 页面周期函数--监听页面加载
  onLoad() {},
  // 页面周期函数--监听页面初次渲染完成
  onReady() {},
  // 页面周期函数--监听页面显示(not-nvue)
  onShow() {},
  // 页面周期函数--监听页面隐藏
  onHide() {
    this.destroysearch();
  },
  // 页面周期函数--监听页面卸载
  onUnload() {},

  // 页面处理函数--监听用户下拉动作
  // onPullDownRefresh() { uni.stopPullDownRefresh(); },
  // 页面处理函数--监听用户上拉触底
  // onReachBottom() {},
  // 页面处理函数--监听页面滚动(not-nvue)
  // onPageScroll(event) {},
  // 页面处理函数--用户点击右上角分享
  // onShareAppMessage(options) {},
});
</script>

<style scoped>
.search {
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store {
  background-color: #fff;
  width: 100%;
  height: 85%;
  padding: 0;
  overflow-y: scroll;
}
.store li {
  list-style: none;
  padding-left: 0;
  width: 100%;
  height: 125px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0px 10px 10px #9c9c9c;
  margin-top: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.store li img {
  background-color: rgb(255, 255, 255);
  width: 110px;
  height: 110px;
  margin-left: 70px;
}
.store li .mes {
  font-size: 15px;
  display: inline-block;
  width: 180px;
  height: 125px;
  margin-left: 20px;
}
.store li .mes1 {
  width: 200px;
}
.store li .mes2 {
  width: 300px;
}
.store li .mes1 span {
  display: inline-block;
  width: 100%;
  height: 31px;
  line-height: 31px;
}
.store li .mes3 span {
  display: inline-block;
  width: 180px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.store li .mes3 span button {
  width: 90px;
  height: 40px;
  color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.page {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_sel {
  display: inline-block;
  display: flex;
  width: 150px;
  height: 30px;
  flex-direction: row;
}
.page_sel select {
  width: 40px;
  height: 30px;
  padding: 0;
  margin: 0;
  background-color: rgb(255, 255, 255);
}
.page_sel button {
  display: flex;
  justify-content: center;
  align-items: center;
}
#Shandow {
  display: block;
  background: #000;
  opacity: 0.2;
  filter: alpha(opacity = 20) \9;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2001;
}
#Delete {
  width: 300px;
  height: 100px;
  border-radius: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  font-size: 18px;
  margin-top: -50px;
  margin-left: -150px;
  background-color: rgb(255, 255, 255);
  z-index: 2050;
}
#Delete #mes {
  display: flex;
  justify-content: center;
  line-height: 65px;
  width: 300px;
  height: 65px;
}
#Delete #YON {
  display: inline-block;
  width: 300px;
  height: 35px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
#Delete #YON button {
  font-size: 18px;
  background-color: aqua;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  margin-left: 60px;
}
#Mod {
  width: 600px;
  height: 800px;
  position: fixed;
  left: 50%;
  top: 50%;
  font-size: 18px;
  margin-top: -400px;
  margin-left: -300px;
  background-color: rgb(255, 255, 255);
  z-index: 2050;
  border-radius: 20px;
}
#Mod #mes {
  width: 600px;
  height: 700px;
  box-sizing: border-box;
}
#Mod #YON {
  display: inline-block;
  width: 600px;
  height: 100px;
  box-sizing: border-box;
}
#Mod #YON button {
  font-size: 20px;
  background-color: aqua;
  border-radius: 14px;
  border: 1px solid #ebebeb;
  margin-left: 133.33px;
}
.url {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
}
.url input {
  width: 350px;
  height: 30px;
  margin-left: 75px;
  font-size: 15px;
}
.m_img {
  width: 150px;
  height: 150px;
  border: 5px dashed #d8d7d7;
  border-radius: 10px;
}
.tip {
  width: 100%;
  margin-left: 70px;
  margin-top: 15px;
}
.title {
  width: 100%;
  height: 100px;
}

/* 对于WebKit浏览器，隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}
</style>
