<template>
  <div class="AddAdmin">
    <el-header class="header">
      <el-button type="primary" plain @click="AddMge()"> 添加管理员 </el-button>
    </el-header>
    <el-main class="Mainbody">
      <div class="body1">
        <el-table :data="employeelist" style="width: 100%" height="100%" border>
          <el-table-column prop="id" label="序号"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column label="手机号">
            <template> ************** </template>
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="50">
          </el-table-column>
          <el-table-column label="状态" width="50">
            <template slot-scope="scope">
              <span
                :style="{ color: scope.row.status === 0 ? 'red' : '#1fff01' }"
              >
                {{ scope.row.status === 0 ? "禁用" : "启用" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column prop="updateTime" label="修改时间">
          </el-table-column>
          <el-table-column prop="createUser" label="创建人"> </el-table-column>
          <el-table-column prop="updateUser" label="修改人"> </el-table-column>
          <el-table-column label="操作" width="250" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="info"
                plain
                @click="detial_Mge(scope.row)"
                v-if="scope.row.classification === 1"
              >
                详情
              </el-button>
              <el-button
                size="mini"
                :type="scope.row.status ? 'danger' : 'success'"
                plain
                @click="YesorNo(scope.row)"
                v-if="scope.row.classification === 1"
              >
                {{ scope.row.status === 0 ? "启用" : "禁用" }}
              </el-button>
              <el-button
                size="mini"
                type="primary"
                plain
                @click="ModifyMge(scope.row)"
                v-if="scope.row.classification === 1"
              >
                修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>

    <el-footer
      style="
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <el-pagination
        layout="prev, pager, next"
        :total="totalemployee.length"
        :page-size="10"
        @current-change="changepage"
      ></el-pagination>
    </el-footer>

    <el-dialog
      title="添加管理员信息"
      width="35%"
      :visible.sync="addDis"
      :append-to-body="true"
      center
    >
      <el-form
        ref="employeeForm"
        :model="employee"
        label-width="100px"
        :rules="employeeRules"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="employee.name" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="用户名" prop="username">
          <el-input v-model="employee.username" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input
            type="number"
            v-model="employee.phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="性别:">
          <el-radio-group v-model="employee.sex">
            <el-radio :label="'男'">男</el-radio>
            <el-radio :label="'女'">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="sure">
          <el-button type="primary" @click="sure()">确定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      title="修改管理员信息"
      width="30%"
      :visible.sync="Moddis"
      :append-to-body="true"
      center
    >
      <el-form :model="employee" label-width="100px">
        <el-form-item label="姓名:">
          <el-input v-model="employee.name" required></el-input>
        </el-form-item>
        <el-form-item label="用户名:">
          <el-input v-model="employee.username" required></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input
            type="password"
            v-model="employee.password"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input type="number" v-model="employee.phone"></el-input>
        </el-form-item>
        <el-form-item label="性别:">
          <el-radio-group v-model="employee.sex">
            <el-radio :label="'男'" @input="get_gender1"></el-radio>
            <el-radio :label="'女'" @input="get_gender2"></el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- <el-form-item label="管理员权限:">
          <el-radio-group v-model="employee.classification">
            <el-radio :label="1">超级管理员</el-radio>
            <el-radio :label="2">普通管理员</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item align="center"> </el-form-item>
        <div style="display: flex; justify-content: center; width: 100%">
          <el-button type="primary" @click="Modsure()">修改</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      title="详情"
      :visible.sync="detialdis"
      width="30%"
      :append-to-body="true"
      center
    >
      <el-form :model="detialMge" label-width="100px" label-position="left">
        <el-form-item label="姓名 : ">
          <span>{{ detialMge.name }}</span>
        </el-form-item>
        <el-form-item label="用户名 : ">
          <span>{{ detialMge.username }}</span>
        </el-form-item>
        <el-form-item label="手机号 : ">
          <span>{{ detialMge.phone }}</span>
        </el-form-item>
        <!-- 出于安全考虑，不直接显示密码 -->
        <el-form-item label="密码 : ">
          <span>{{ detialMge.password }}</span>
        </el-form-item>
        <el-form-item label="性别 : ">
          <span>{{ detialMge.sex }}</span>
        </el-form-item>
        <div
          class="btn"
          style="display: flex; justify-content: center; margin-top: 20px"
        >
          <el-button type="primary" @click="detialMgesure()">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Vue, { toRefs } from "vue";
import axios from "axios";
import bus from "../eventBus.js";
export default Vue.extend({
  components: {},
  data() {
    return {
      id: "",
      token: null,
      Mge: "",
      Mgelist: [],
      addDis: false,
      deldis: false,
      delHtml: "",
      Moddis: false,
      detialdis: false,
      detialMge: "",
      male: false,
      female: false,
      staus1: false,
      staus2: false,
      delname: "",
      classification: "",
      totalemployee: [],
      employeelist: [],
      now_page: 1,
      employeeRules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          // 可以添加更多验证规则
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          // 用户名验证规则
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // 可以添加更多手机号验证规则，如长度、格式等
        ],
      },
      employee: {
        id: null,
        name: "",
        username: "",
        password: null,
        phone: null,
        sex: "男",
        status: null,
        create_time: "",
        update_time: "",
        create_user: "",
        update_user: "",
        classification: "",
        btn_dis: "none",
      },
      di: "1",
    };
  },
  created() {
    this.id = localStorage.getItem("id");
    this.token = localStorage.getItem("token");
    this.classification = localStorage.getItem("classification");
    this.get_mgr();
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    //对象置空
    get_mgr() {
      let config = {
        params: {
          pageNo: 1,
          pageSize: 1000,
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/employee/page", config).then((res) => {
        this.Mgelist = res.data.data.records;
        this.totalemployee = res.data.data.records;
        if (this.classification == 1) {
          for (let i = 0; i < this.totalemployee.length; i++) {
            this.totalemployee[i].classification = 1;
          }
        } else {
          for (let i = 0; i < this.totalemployee.length; i++) {
            this.totalemployee[i].classification = 2;
          }
        }
        for (let i = 0; i < this.totalemployee.length; i++) {
          if (this.totalemployee[i].sex == 1) {
            this.totalemployee[i].sex = "男";
          } else {
            this.totalemployee[i].sex = "女";
          }
        }
        this.data_page();
      });
    },
    data_page() {
      this.now_page = 1;
      let n = Math.ceil(this.totalemployee.length / 17);
      this.pagelist = Array.from({ length: n }, (v, k) => k + 1);
      this.changepage();
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.employeelist = this.totalemployee.slice((page - 1) * 17, page * 17);
    },
    MgeNull() {
      for (let key in this.employee) {
        if (this.employee.hasOwnProperty(key)) {
          // 使用hasOwnProperty来检查属性是否是对象自身的（而非从原型链上继承的）
          this.employee[key] = "";
        }
      }
      this.male = false;
      this.female = false;
      this.staus1 = false;
      this.staus2 = false;
    },
    YesorNo(e) {
      let status = !e.status ? 1 : 0;
      axios({
        method: "post",
        url: `/api/admin/employee/status/${status}`,
        params: {
          id: e.id,
        },
        headers: {
          "Content-Type": "application/json",
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data.code == 1) {
            e.status = status;
            this.$message({
              message: e.status ? "启用成功" : "禁用成功",
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.$message.error("修改失败");
        });
    },
    //-----------------
    detial_Mge(item) {
      this.detialMge = item;
      this.detialdis = true;
    },
    detialMgesure() {
      this.detialdis = false;
    },
    AddMge() {
      this.addDis = true;
    },
    ModifyMge(e) {
      console.log(e);
      this.Mge = e;
      this.employee.name = e.name;
      this.employee.username = e.username;
      this.employee.password = e.password;
      this.employee.phone = e.phone;
      this.employee.sex = e.sex;
      this.employee.status = e.status;
      this.employee.classification = e.classification;

      if (e.sex == "男") {
        this.male = true;
      } else {
        this.female = true;
      }
      if (e.status == 1) {
        this.staus1 = true;
      } else {
        this.staus2 = true;
      }
      this.Moddis = true;
    },

    Modsure() {
      axios
        .put(
          "/api/admin/employee",
          {
            id: this.Mge.id,
            username: this.employee.username,
            password: this.employee.password,
            name: this.employee.name,
            phone: this.employee.phone,
            sex: this.employee.sex,
            classification: 0,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.get_mgr();
            this.$message({
              message: "修改成功",
            });
          } else {
            this.get_mgr();
            this.$message.error("修改失败" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("修改失败");
        });
      this.Moddis = false;
      this.MgeNull();
    },
    //--------------------
    get_gender1(e) {
      console.log(this.employee.sex);
      this.employee.sex = "男";
    },
    get_gender2(e) {
      this.employee.sex = "女";
    },
    get_staus1() {
      this.employee.status = 1;
    },
    get_staus2() {
      this.employee.status = 0;
    },
    getpermissions(e) {
      console.log(e);
    },
    //确认之后添加元素
    sure() {
      if (this.employee.name == "") {
        this.namedis = "block";
      } else {
        if (this.employee.username == "") {
          this.usernamedis = "block";
        } else {
          if (
            this.employee.phone == null ||
            !/^\d+$/.test(this.employee.phone)
          ) {
            this.phonedis = "block";
          } else {
            if (this.employee.sex == "") {
              this.sexdis = "block";
            } else {
              let employee = {
                id: null,
                name: "",
                username: "",
                password: null,
                phone: null,
                sex: "",
                status: null,
                create_time: "",
                update_time: "",
                create_user: "",
                update_user: "",
              };
              employee.name = this.employee.name;
              employee.username = this.employee.username;
              employee.password = this.employee.password;
              employee.phone = this.employee.phone;
              employee.sex = this.employee.sex;
              axios
                .post(
                  "/api/admin/employee",
                  {
                    id: 0,
                    username: this.employee.username,
                    password: 123456,
                    name: this.employee.name,
                    phone: this.employee.phone,
                    sex: this.employee.sex,
                    status: 0,
                    classification: 0,
                  },
                  {
                    headers: {
                      token: this.token, //请求头是用token
                    },
                  }
                )
                .then((res) => {
                  console.log(111);
                  if (res.data.code == 1) {
                    this.$message({
                      message: "添加成功",
                      type: "success",
                    });
                    this.get_mgr();
                  } else {
                    this.$message("添加失败");
                  }
                })
                .catch((err) => {
                  this.tipmsg = "添加失败";
                  this.show_msg();
                });
              this.addDis = false;
              this.MgeNull();
            }
          }
        }
      }
    },
    //----------------
  },
  watch: {},
});
</script>

<style scoped>
.AddAdmin {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
}
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 8%;
  padding-right: 100px;
}
.Mainbody {
  width: 100%;
  height: 82%;
  display: flex;
  justify-content: center;
}
.body1 {
  width: 90%;
  height: 100%;
}
.footer {
  line-height: 80px;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
}
.sure {
  display: flex;
  justify-content: center;
  width: 100%;
}

.T {
  display: flex;
  box-sizing: border-box;
  padding-left: 60px;
  margin-bottom: 15px;
  width: 100%;
  font-size: 16px;
}
.even-row {
  background-color: white; /* 白色背景 */
}

.odd-row {
  background-color: #f0f0f0; /* 灰色背景 */
}

::-webkit-scrollbar {
  display: none;
}
</style>
