<template>
  <div class="icSpaceCategory">
    <el-header class="header">
      <el-radio-group
        style="display: flex; flex-direction: row"
        v-model="twoSelone"
        @input="selArea_or_Seat"
      >
        <el-radio-button :label="true">研讨间</el-radio-button>
        <el-radio-button :label="false">座位</el-radio-button>
      </el-radio-group>
      <el-header class="header1">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-upload"
          @click="add_dis = true"
        >
          新增分类
        </el-button>
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          @click="delCategory()"
        >
          删除分类
        </el-button>
      </el-header>
    </el-header>
    <el-main class="body flex">
      <div class="body1">
        <el-table
          @selection-change="seldel"
          :data="Categorylist"
          style="width: 100%"
          height="100%"
          border
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="分类名"
            width="250"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="所属校区"
            width="250"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ getTypeLabel(scope.row.campusId) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="mod_func(scope.row)"
              >
                修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <el-footer class="footer flex">
      <el-pagination
        layout="prev, pager, next"
        :total="totalIcCategory.length"
        :page-size="14"
        @current-change="changepage"
      ></el-pagination>
    </el-footer>
    <!-- 添加分类 -->
    <el-dialog
      title="添加分类"
      width="30%"
      :visible.sync="add_dis"
      :append-to-body="true"
      :modal="true"
      :center="true"
    >
      <div>
        <el-form
          :model="add"
          :rules="addrules"
          label-position="top"
          label-width="100px"
        >
          <el-form-item label="分类名称" prop="name">
            <el-input v-model="add.name"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="sort">
            <el-select v-model="add.type" placeholder="请选择">
              <el-option
                v-for="item in typelist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="校区" prop="sort">
            <el-select v-model="add.campusId" placeholder="请选择">
              <el-option
                v-for="item in campusIdlist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="flex">
        <div>
          <el-button type="primary" @click="add_sure()">确定添加</el-button>
        </div>
      </span>
    </el-dialog>

    <!-- 修改分类 -->
    <el-dialog
      title="修改分类"
      width="30%"
      :visible.sync="mod_dis"
      :append-to-body="true"
      :modal="true"
      :center="true"
    >
      <div>
        <el-form
          :model="mod"
          :rules="addrules"
          label-width="100px"
          label-position="top"
        >
          <el-form-item label="分类名称" prop="name">
            <el-input v-model="mod.name"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="sort">
            <el-select v-model="mod.type" placeholder="请选择">
              <el-option
                v-for="item in typelist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="校区" prop="sort">
            <el-select v-model="mod.campusId" placeholder="请选择">
              <el-option
                v-for="item in campusIdlist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="flex">
        <div>
          <el-button type="primary" @click="mod_sure()">确定修改</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="del_dis"
      width="25%"
      style="height: 300vh"
      :append-to-body="true"
    >
      <div style="display: flex; justify-content: center; font-size: 1.5rem">
        确定要删除选中项吗
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 50px;
        "
      >
        <el-button type="primary" @click="del_sure()"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      twoSelone: true,
      now_page: 1,
      Categorylist: [],
      totalIcCategory: [],
      typelist: [
        { value: 0, label: "研讨间" },
        { value: 1, label: "座位" },
      ],
      campusIdlist: [
        { value: 1, label: "大学城校区" },
        { value: 2, label: "桂花岗校区" },
        { value: 3, label: "黄埔校区" },
      ],
      add_dis: false,
      mod_dis: false,
      add: {
        name: "",
        type: 0,
        campusId: 1,
      },
      mod: {
        id: 0,
        name: "",
        type: 0,
        campusId: 1,
      },
      addrules: {
        name: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
      del_dis: false,
      delobj: [],
      delIdList: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getCategory(0);
  },
  computed: {},
  methods: {
    selArea_or_Seat(e) {
      e ? this.getCategory(0) : this.getCategory(1);
    },
    getTypeLabel(typeName) {
      switch (typeName) {
        case 1:
          return "大学城校区";
        case 2:
          return "桂花岗校区";
        case 3:
          return "黄埔校区";
        default:
          return "出错";
      }
    },
    getCategory(type) {
      this.now_page = 1;
      let config = {
        params: {
          pageNo: 1,
          pageSize: 100,
        },
        headers: {
          token: this.token,
        },
      };
      axios
        .get(`/api/admin/ic-space-category/type/${type}`, config)
        .then((res) => {
          if (res.data.code == 1) {
            this.totalIcCategory = [];
            res.data.data.forEach((item) => {
              item.categoryList.forEach((item1) => {
                this.totalIcCategory.push(item1);
              });
            });
            this.Categorylist = this.totalIcCategory.slice(
              (this.now_page - 1) * 14,
              this.now_page * 14
            );
          }
        });
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.Categorylist = this.totalIcCategory.slice(
        (this.now_page - 1) * 14,
        this.now_page * 14
      );
    },
    add_sure() {
      if (this.add.name) {
        axios
          .post(
            "/api/admin/ic-space-category",
            {
              name: this.add.name,
              type: this.add.type,
              campusId: this.add.campusId,
            },
            {
              headers: {
                token: this.token,
              },
            }
          )
          .then((res) => {
            if (res.data.code == 1) {
              this.add_dis = false;
              this.$message.success({
                message: "添加成功",
                type: "success",
              });
              this.getCategory(this.twoSelone ? 0 : 1);
            } else {
              this.$message.error({
                message: "添加失败, " + res.data.msg,
                type: "warning",
              });
            }
            this.add.name = "";
            this.add.type = 0;
            this.add.campusId = 1;
          });
      } else {
        this.$message.error({
          message: "请输入分类名称",
          type: "warning",
        });
      }
    },
    mod_func(item) {
      this.mod.id = item.id;
      this.mod.name = item.name;
      this.mod.type = item.type;
      this.mod.campusId = item.campusId;
      this.mod_dis = true;
    },
    mod_sure() {
      if (this.mod.name) {
        axios
          .put(
            "/api/admin/ic-space-category",
            {
              id: Number(this.mod.id),
              name: this.mod.name,
              type: Number(this.mod.type),
              campusId: Number(this.mod.campusId),
            },
            {
              headers: {
                "Content-Type": "application/json",
                token: this.token,
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.mod_dis = false;
            if (res.data.code == 1) {
              this.$message.success({
                message: "修改成功",
                type: "success",
              });
              this.getCategory(this.twoSelone ? 0 : 1);
            } else {
              this.$message.error({
                message: "修改失败, " + res.data.msg,
                type: "warning",
              });
            }
          });
      } else {
        this.$message.error({
          message: "请输入分类名称",
          type: "warning",
        });
      }
    },
    seldel(e) {
      this.delobj = e;
    },
    delCategory() {
      if (this.delobj.length == 0) {
        this.$message.error({
          message: "请选择要删除的分类",
          type: "warning",
        });
      } else {
        console.log("1111");
        this.del_dis = true;
      }
    },
    del_sure() {
      this.delIdList = [];
      this.delobj.forEach((item) => {
        this.delIdList.push(item.id);
      });
      let ids = this.delIdList; // 假设这是一个包含ID的数组
      let idStr = ids.join(",");
      axios
        .delete(`/api/admin/ic-space-category`, {
          headers: {
            token: this.token,
          },
          params: {
            ids: idStr,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message.success({
              message: "删除成功",
              type: "success",
            });
            this.getCategory(this.twoSelone ? 0 : 1);
          } else {
            this.$message.error({
              message: "删除失败",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message.error({
            message: "删除失败",
            type: "warning",
          });
        });
      this.del_dis = false;
    },
  },
  watch: {},
  mounted() {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.icSpaceCategory {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.header {
  display: flex;
  width: 100%;
  height: 9%;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-left: 70px;
}
.header1 {
  display: flex;
  align-items: center;
  padding-left: 25px;
}
.body {
  width: 100%;
  height: 81%;
}
.body1 {
  width: 90%;
  height: 100%;
}
.footer {
  width: 100%;
  /* height: 6%; */
}
</style>
