<template>
  <div class="classify">
    <el-header class="header">
      <el-button type="primary" plain class="add-button" @click="add()">
        <i class="el-icon-plus"></i>
        新增分类
      </el-button>
    </el-header>
    <div class="table_head">
      <span>分类名称</span>
      <span>排序</span>
      <span>状态</span>
      <span>操作时间</span>
      <span>操作</span>
    </div>
    <div class="body">
      <div class="body_class" v-for="(item, index) in Colist" :key="index">
        <span>{{ item.name }}</span>
        <span>{{ item.sort }}</span>
        <span>{{ item.status ? "启用" : "禁用" }}</span>
        <span>{{ item.updateTime }}</span>
        <span class="actions">
          <el-button type="primary" size="mini" @click="mod(item)"
            >修改</el-button
          >
          <el-button type="danger" size="mini" @click="del(item)"
            >删除</el-button
          >
          <el-button
            :type="item.status ? 'warning' : 'success'"
            size="mini"
            @click="chstatus(item)"
          >
            {{ item.status ? "禁用" : "启用" }}
          </el-button>
        </span>
      </div>
    </div>
    <div
      style="
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <el-pagination
        layout="prev, pager, next"
        :total="totalcal.length"
        :page-size="17"
        @current-change="changepage"
      ></el-pagination>
    </div>

    <el-dialog
      title="添加分类"
      width="30%"
      :visible.sync="add_dis"
      :append-to-body="true"
      center
    >
      <div>
        <el-form>
          <el-form-item label="分类名称">
            <el-input v-model="add_name"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="add_sort"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_cal()">取消</el-button>
        <el-button type="primary" @click="add_sure()">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改分类"
      :visible.sync="mod_dis"
      :append-to-body="true"
      width="30%"
      center
    >
      <div>
        <el-form>
          <el-form-item label="分类名称">
            <el-input v-model="sortname"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="sort"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mod_cal()">取消</el-button>
        <el-button type="primary" @click="mod_sure()">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="删除分类"
      :visible.sync="del_dis"
      :append-to-body="true"
      width="30%"
    >
      <div>是否删除分类 "{{ sortname }}"</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="del_cal()">取消</el-button>
        <el-button type="primary" @click="del_sure()">确定</el-button>
      </span>
    </el-dialog>

    <el-notification
      v-if="showmsg === 'block'"
      :title="tipmsg"
      type="info"
      :duration="1000"
    >
    </el-notification>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  data() {
    return {
      showmsg: "none",
      tipmsg: "",
      add_name: "",
      add_sort: "",
      status: 1,
      sortname: "",
      token: null,
      sort: "",
      add_dis: false,
      mod_dis: false,
      del_dis: false,
      class: "",
      totalcal: [],
      Colist: [],
      now_page: 1,
      pagelist: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.get_class();
  },
  methods: {
    show_msg() {
      this.showmsg = "block";
      setTimeout(() => {
        this.showmsg = "none";
      }, 1000);
    },
    get_class() {
      let config = {
        params: {
          pageNo: 1,
          pageSize: 100,
        },
        headers: {
          token: this.token,
        },
      };
      axios
        .get("/api/admin/category/page", config)
        .then((response) => {
          this.totalcal = response.data.data.records;
          this.clssort();
          this.data_page();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    data_page() {
      this.now_page = 1;
      let n = Math.ceil(this.totalcal.length / 17);
      this.pagelist = Array.from({ length: n }, (v, k) => k + 1);
      this.changepage();
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.Colist = this.totalcal.slice((page - 1) * 17, page * 17);
    },
    clssort() {
      this.Colist.sort((a, b) => {
        return a.sort - b.sort;
      });
    },
    add() {
      this.add_dis = true;
    },
    add_sure() {
      const token = localStorage.getItem("token");
      axios
        .post(
          "/api/admin/category",
          {
            id: 0,
            name: this.add_name,
            sort: this.add_sort,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.get_class();
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message.error("添加失败");
          }
        })
        .catch((err) => {});
      this.add_name = "";
      this.add_sort = "";
      this.add_dis = false;
    },
    add_cal() {
      this.add_name = "";
      this.add_sort = "";
      this.add_dis = false;
    },
    mod(item) {
      this.class = item;
      this.sortname = item.name;
      this.sort = item.sort;
      this.mod_dis = true;
    },
    mod_sure() {
      axios
        .put(
          "/api/admin/category",
          {
            id: this.class.id,
            name: this.sortname,
            sort: this.sort,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.get_class();
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message.error("修改失败，请先禁用分类");
          }
        });
      this.sortname = "";
      this.sort = "";
      this.mod_dis = false;
    },
    mod_cal() {
      this.sortname = "";
      this.sort = "";
      this.mod_dis = false;
    },
    del(e) {
      this.sortname = e.name;
      this.class = e;
      this.del_dis = true;
    },
    del_sure() {
      let config = {
        params: {
          ids: this.class.id,
        },
        headers: {
          token: this.token,
        },
      };
      axios.delete("/api/admin/category", config).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.get_class();
        } else {
          this.$message.error("删除失败，请先禁用分类");
        }
      });
      this.del_dis = false;
    },
    del_cal() {
      this.del_dis = false;
    },
    // 在 methods 中的 chstatus 方法中，确保发送请求时包含分类的 id 参数
    chstatus(item) {
      let newStatus = item.status ? 0 : 1; // 切换状态
      console.log(newStatus);

      axios({
        method: "post",
        url: `/api/admin/category/status/${newStatus}`,
        params: {
          id: item.id,
        },
        headers: {
          "Content-Type": "application/json",
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data.code == 1) {
            this.tipmsg = newStatus
              ? this.$message({
                  message: "启用成功",
                  type: "success",
                })
              : this.$message.error("禁用成功");

            item.status = newStatus; // 更新前端显示状态
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          this.$message.error("操作失败");
        });
    },
  },
});
</script>

<style scoped>
.classify {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
}

.header {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.add-button {
  margin-right: 100px;
}

.table_head {
  display: flex;
  align-items: center;
  width: 90%;
  height: 40px;
  font-size: 14px;
  margin: 0 auto;
  border-bottom: 1px solid #ebe9e9;
}

.table_head span {
  width: 20%;
  text-align: center;
}

.body {
  overflow: auto;
  width: 90%;
  height: 75%;
  margin: 0 auto;
  border-bottom: 1px solid #ebebeb;
}

.body_class {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  border-bottom: 1px solid #ebebeb;
}

.body_class span {
  width: 20%;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  font-size: 16px;
  text-align: center;
  background-color: #f8f8f8;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
