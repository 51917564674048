<template>
  <div class="note">
    <div class="title">通知!!!</div>
    <div class="title1">
      <el-button type="primary" @click="add()">
        发布新通知
        <i class="el-icon-upload el-icon--right"></i>
      </el-button>
    </div>
    <div class="givenote">
      <div class="givenote1">
        <div class="notebody" style="box-sizing: border-box">
          <el-table :data="notelist" style="width: 100%" height="100%" border>
            <el-table-column prop="description" label="内容" width="600">
            </el-table-column>
            <el-table-column label="状态" width="100" align="center">
              <template slot-scope="scope">
                <span
                  style="font-weight: bolder"
                  :style="{ color: scope.row.status ? 'green' : 'red' }"
                >
                  {{ scope.row.status ? "启用" : "禁用" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="modNote(scope.row)"
                >
                  修改
                </el-button>
                <el-button
                  :type="scope.row.status === 1 ? 'danger' : 'success'"
                  size="mini"
                  @click="YON(scope.row)"
                >
                  {{ scope.row.status === 1 ? "禁用" : "启用" }}
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  @click="del_note(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          style="
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <el-pagination
            layout="prev, pager, next"
            :total="totalnote.length"
            :page-size="10"
            @current-change="changepage"
          ></el-pagination>
        </div>

        <el-dialog
          :visible.sync="add_dis"
          width="30%"
          :append-to-body="true"
          title="新增通知"
          center
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            v-model="note"
            maxlength="300"
            show-word-limit
            placeholder="请输入通知内容"
          >
          </el-input>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="add_note()">发 布</el-button>
          </div>
        </el-dialog>

        <el-dialog
          :visible.sync="mod_dis"
          width="30%"
          :append-to-body="true"
          title="修改通知"
          center
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            v-model="mod_dec"
            maxlength="300"
            show-word-limit
            placeholder="请输入通知内容"
          >
          </el-input>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="mod_sure()">修改</el-button>
          </div>
        </el-dialog>

        <el-dialog
          :visible.sync="del_dis"
          width="30%"
          :append-to-body="true"
          title="是否删除该通知"
          center
        >
          <div
            style="
              width: 100%;
              height: 60px;
              display: flex;
              justify-content: center;
              text-align: center;
            "
          >
            {{ del_dec }}
          </div>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <el-button type="primary" @click="del_sure()">删除</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      showmsg: "none",
      tipmsg: "",
      totalnote: [],
      notelist: [],
      now_page: 1,
      pagelist: [],
      note: "",
      clo: 0,
      modnote: "",
      mod_dec: "",
      delnote: "",
      del_dec: "",
      add_dis: false,
      del_dis: false,
      mod_dis: false,
      give_note_dis: "none",
    };
  },
  computed: {},
  created() {
    this.token = localStorage.getItem("token");
    this.get_note();
  },
  methods: {
    get_note() {
      this.sh_addDis = "block";
      let config = {
        params: {
          pageNo: 1,
          pageSize: 100,
        },
        headers: {
          token: this.token,
        },
      };
      axios
        .get("/api/admin/notice/page", config)
        .then((res) => {
          this.totalnote = res.data.data.records;
          this.data_page();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    data_page() {
      this.now_page = 1;
      let n = Math.ceil(this.totalnote.length / 17);
      this.pagelist = Array.from({ length: n }, (v, k) => k + 1);
      this.changepage();
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.notelist = this.totalnote.slice((page - 1) * 17, page * 17);
    },
    add() {
      this.add_dis = true;
    },
    add_note() {
      axios
        .post(
          "/api/admin/notice",
          { id: 0, description: this.note },
          { headers: { token: this.token } }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "发布成功",
              type: "success",
            });
            this.get_note();
          } else {
            this.$message.error("发布失败");
          }
          this.note = "";
        })
        .catch((err) => {
          this.note = "";
        });
      this.add_dis = false;
    },
    modNote(e) {
      this.modnote = e;
      this.mod_dec = e.description;
      this.mod_dis = true;
    },
    mod_sure() {
      axios
        .put(
          "/api/admin/notice",
          {
            id: this.modnote.id,
            description: this.mod_dec,
          },
          { headers: { token: this.token } }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.get_note();
          } else {
            this.$message.error("修改失败" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("修改失败");
        });
      this.mod_dis = false;
    },
    del_note(e) {
      this.delnote = e;
      this.del_dec = e.description;
      this.del_dis = true;
    },
    del_sure() {
      axios
        .delete("/api/admin/notice", {
          params: { ids: this.delnote.id },
          headers: { token: this.token },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.get_note();
          } else {
            this.$message.error("删除失败" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });

      this.del_dis = false;
    },
    del_cancel() {
      this.add_dis = "block";
      this.del_dis = "none";
      this.mod_dis = "none";
    },
    YON(e) {
      let status = !e.status ? 1 : 0;
      axios({
        method: "post",
        url: `/api/admin/notice/status/${status}`,
        params: {
          id: e.id,
        },
        headers: {
          "Content-Type": "application/json",
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data.code == 1) {
            e.status = !e.status ? 1 : 0;
            this.$message({
              message: e.status ? "启用成功" : "禁用成功",
              type: "success",
            });
          } else {
            this.$message.error(e.status ? "禁用失败" : "启用失败");
          }
        })
        .catch((error) => {
          this.$message.error("操作失败");
        });
    },
  },
  watch: {},

  // 页面周期函数--监听页面加载
  onLoad() {},
  // 页面周期函数--监听页面初次渲染完成
  onReady() {},
  // 页面周期函数--监听页面显示(not-nvue)
  onShow() {},
  // 页面周期函数--监听页面隐藏
  onHide() {},
  // 页面周期函数--监听页面卸载
  onUnload() {},
  // 页面处理函数--监听用户下拉动作
  // onPullDownRefresh() { uni.stopPullDownRefresh(); },
  // 页面处理函数--监听用户上拉触底
  // onReachBottom() {},
  // 页面处理函数--监听页面滚动(not-nvue)
  // onPageScroll(event) {},
  // 页面处理函数--用户点击右上角分享
  // onShareAppMessage(options) {},
});
</script>

<style scoped>
.note {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.title {
  width: 100%;
  height: 10%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 6%;
  font-size: 2rem;
  font-weight: bolder;
  color: #5d1ff9;
}
.title1 {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  position: relative;
  padding-left: 75%;
}
.givenote {
  width: 100%;
  height: 85%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
}
.btn {
  width: 70px;
  height: 30px;
  font-size: 15px;
}
.givenote1 {
  width: 90%;
  height: 100%;
}
.noteheader {
  width: 100%;
  height: 10px;
  background-color: #ffffff;
}
.notebody {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: #ffffff;
}
.notetxt {
  width: 100%;
  height: 40px;
  font-size: 16px;
  word-wrap: break-word;
  overflow-y: scroll;
}
.notefoot {
  width: 80%;
  height: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  box-sizing: border-box;
}
.notefoot textarea {
  width: 90%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
}
.notefoot1 {
  width: 400px;
  height: 30px;
}
.mod_note {
  width: 80%;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  box-sizing: border-box;
}
.mod_note textarea {
  width: 90%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
}
.del_note {
  width: 80%;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 10px;
  border-top: 0px solid #333;
  border-bottom: 0px solid #333;
}
.del_note1 {
  width: 90%;
  height: 80px;
  font-size: 15px;
  padding: 10px;
  word-wrap: break-word;
  overflow-y: scroll;
  border: 1px solid #f70000;
}
.showmsg {
  width: 200px;
  height: 75px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
  border-radius: 30px;
  background-color: grey;
  display: flex; /* 添加flex布局 */
  text-align: center;
  line-height: 75px;
  font-size: 13px;
  color: #ffffff;
}
::-webkit-scrollbar {
  display: none;
}
</style>
