<template>
  <div class="homeAd">
    <div class="header al">
      <div>
        <el-button type="primary" size="mini" @click="add_dis = true">
          <i class="el-icon-upload el-icon--right"></i>
          新增图片
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-delete"
          size="mini"
          style="background-color: red"
          @click="del()"
        >
          批量删除
        </el-button>
      </div>
    </div>
    <div class="ebody js">
      <div class="body1">
        <el-table
          :data="homeimglist"
          style="width: 100%"
          border
          @selection-change="selDel"
        >
          <el-table-column type="selection" width="100"></el-table-column>
          <el-table-column label="图片" width="100" align="center">
            <template slot-scope="scope">
              <img
                :src="scope.row.img"
                alt=""
                style="width: 50px; height: auto"
              />
            </template>
          </el-table-column>
          <el-table-column label="图片" width="100" align="center">
            <template slot-scope="scope">
              <img
                :src="scope.row.detailImg"
                alt=""
                style="width: 50px; height: auto"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="广告描述"
            width="180"
            align="center"
            prop="description"
          >
          </el-table-column>
          <el-table-column label="状态" width="180" align="center">
            <template slot-scope="scope">
              <el-button
                plain
                size="small"
                :type="scope.row.status == 1 ? 'success' : 'danger'"
              >
                {{ scope.row.status == 1 ? "启用" : "禁用" }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="100"
            align="center"
            prop="createTime"
          ></el-table-column>
          <el-table-column
            label="创建人"
            width="100"
            align="center"
            prop="createUser"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                plain
                size="small"
                type="primary"
                @click="detailAd(scope.row)"
                >详情</el-button
              >
              <el-button
                plain
                size="small"
                type="primary"
                @click="modAd(scope.row)"
                >修改</el-button
              >
              <el-button
                plain
                size="small"
                :type="scope.row.status != 1 ? 'success' : 'danger'"
                @click="chnageStatus(scope.row)"
              >
                {{ scope.row.status != 1 ? "启用" : "禁用" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="footer flex"></div>
    <!-- 新增广告 -->
    <el-dialog
      title="新增广告"
      :visible.sync="add_dis"
      center
      :append-to-body="true"
      width="30%"
    >
      <el-form style="height: 50vh; overflow-y: scroll" label-position="top">
        <el-form-item label="广告卡片:">
          <el-image
            :src="add.img"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            action="#"
            :auto-upload="false"
            :on-change="addFile"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取图片
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="广告详情图片:">
          <el-image
            :src="add.detailImg"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            action="#"
            :auto-upload="false"
            :on-change="addFile1"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取图片
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="广告描述">
          <el-input v-model="add.description"></el-input>
        </el-form-item>
      </el-form>

      <div style="width: 100%; display: flex; justify-content: center">
        <el-button type="primary" @click="add_sure()">确定添加</el-button>
      </div>
    </el-dialog>

    <!-- 修改广告内容 -->
    <el-dialog
      title="修改广告"
      :visible.sync="mod_dis"
      center
      :append-to-body="true"
      width="30%"
    >
      <el-form style="height: 50vh; overflow-y: scroll" label-position="top">
        <el-form-item label="广告卡片:">
          <el-image
            :src="mod.img"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            action="#"
            :auto-upload="false"
            :on-change="modFile"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取图片
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="广告详情图片:">
          <el-image
            :src="mod.detailImg"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            action="#"
            :auto-upload="false"
            :on-change="modFile1"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取图片
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="广告描述">
          <el-input v-model="mod.description"></el-input>
        </el-form-item>
      </el-form>

      <div style="width: 100%; display: flex; justify-content: center">
        <el-button type="primary" @click="mod_sure()">确定修改</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="detail_dis"
      center
      :append-to-body="true"
      width="30%"
    >
      <el-card
        class="box-card"
        shadow="hover"
        style="max-height: 60vh; overflow-y: scroll"
      >
        <div
          slot="header"
          class="clearfix"
          style="display: flex; justify-content: center"
        >
          <span>详情信息</span>
        </div>

        <div>
          <div style="widows: 100%; display: flex; justify-content: center">
            <el-image
              :src="detail.img"
              fit="contain"
              style="width: 400px; height: 400px; margin-bottom: 20px"
            ></el-image>
          </div>

          <div style="widows: 100%; display: flex; justify-content: center">
            <el-image
              :src="detail.detailImg"
              fit="contain"
              style="width: 400px; height: 400px; margin-bottom: 20px"
            ></el-image>
          </div>
          <div>
            <p>广告描述</p>
            <p>{{ detail.description }}</p>
          </div>
        </div>
      </el-card>
      <div style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="detail_dis = false">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="del_dis"
      width="20%"
      center
      :append-to-body="true"
    >
      <span
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        确定要删除选中项吗
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del_sure()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      homeimglist: [],
      add_dis: false,
      add: {
        img: "",
        detailImg: "",
        description: "",
      },
      mod: {},
      mod_dis: false,
      del_dis: false,
      delobject: [],
      detail_dis: false,
      detail: {},
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.gethomeimage();
  },
  computed: {},
  methods: {
    async filePut(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },
    gethomeimage() {
      this.homeimglist = [];
      axios
        .get("/api/admin/home-img/all", { headers: { token: this.token } })
        .then((res) => {
          if (res.data.code == 1) {
            this.homeimglist = res.data.data;
          }
        });
    },
    async addFile(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.add.img = img.data;
    },
    async addFile1(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.add.detailImg = img.data;
    },

    add_sure() {
      axios
        .post(
          "/api/admin/home-img/add",
          {
            img: this.add.img,
            detailImg: this.add.detailImg,
            description: this.add.description,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.gethomeimage();
          } else {
            this.$message.error("添加失败");
          }
        });
      this.add.img = "";
      this.add.detailImg = "";
      this.add.description = "";
      this.add_dis = false;
    },
    modAd(e) {
      this.mod = e;
      this.mod_dis = true;
    },
    async modFile(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.mod.img = img.data;
    },
    async modFile1(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.mod.detailImg = img.data;
    },
    mod_sure() {
      console.log(this.mod);
      let th = this;
      axios
        .put(
          "/api/admin/home-img/update",
          {
            id: this.mod.id,
            img: this.mod.img,
            detailImg: this.mod.detailImg,
            description: this.mod.description,
          },
          {
            headers: {
              token: th.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            th.gethomeimage();
          } else {
            this.$message.error("修改失败");
          }
          th.mod_dis = false;
        })
        .catch((err) => {
          this.$message.error("修改失败");
        });
    },
    detailAd(e) {
      this.detail_dis = true;
      this.detail = e;
    },
    selDel(e) {
      this.delobject = e;
    },
    del() {
      if (this.delobject.length <= 0) {
        this.$message.error("未选择任何项");
      } else {
        this.del_dis = true;
      }
    },
    del_sure() {
      this.ids = [];
      this.delobject.forEach((item) => {
        this.ids.push(item.id);
      });
      let ids = this.ids; // 假设这是一个包含ID的数组
      let idStr = ids.join(","); // 将数组转换为逗号分隔的字符串
      axios
        .delete(`/api/admin/home-img/delete?ids=${idStr}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.gethomeimage();
          } else {
            this.$message.error("删除失败" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
      this.del_dis = false;
    },
    chnageStatus(item) {
      let newStatus = item.status ? 0 : 1; // 切换状态
      axios({
        method: "put",
        url: `/api/admin/home-img/change/${item.id}/${newStatus}`,
        headers: {
          "Content-Type": "application/json",
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: newStatus ? "启用成功" : "禁用成功",
              type: "success",
            });
            item.status = newStatus; // 更新前端显示状态
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          this.$message.error("操作失败");
        });
    },
  },
  watch: {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.homeAd {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.header {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 10%;
  padding-right: 100px;
}
.ebody {
  width: 100%;
  height: 80%;
  box-sizing: border-box;
}
.body1 {
  width: 90%;
  height: 100%;
  overflow-y: scroll;
}
.footer {
  width: 100%;
  height: 8%;
}
/* ::-webkit-scrollbar {
  display: none;
} */
</style>
