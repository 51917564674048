import Vue from 'vue'
import App from './App.vue'
import router from '../src/router/index.js'
import * as echarts from 'echarts';//引入echarts


import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import '../src/main.css'
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts //引入组件
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
