<template>
  <div class="Feedback" style="box-sizing: border-box">
    <el-header class="title"> 用户反馈记录 </el-header>

    <div class="flex" style="width: 100%; height: 80%">
      <div style="width: 85%; height: 100%">
        <el-table :data="backlist" height="100%" border>
          <el-table-column label="图片" width="150" align="center">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.image1" size="medium" shape="square" />
            </template>
          </el-table-column>
          <el-table-column label="描述" width="400">
            <template slot-scope="scope">
              {{ scope.row.message }}
            </template>
          </el-table-column>
          <el-table-column label="时间" width="200" align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="primary"
                @click="detailback(scope.row)"
                >详情</el-button
              >
              <el-button
                size="small"
                :type="scope.row.status === 1 ? 'success' : 'danger'"
                @click="scope.row.status === 0 ? sure_back(scope.row) : ''"
              >
                {{ scope.row.status === 1 ? "已确认" : "待确认" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      class="Feeddetail"
      :visible.sync="showDetail"
      title="详情"
      :append-to-body="true"
      width="40%"
      center
    >
      <el-carousel trigger="click">
        <el-carousel-item class="dimg" v-if="detail_feed.image1">
          <img :src="detail_feed.image1" alt="image1" />
        </el-carousel-item>
        <el-carousel-item class="dimg" v-if="detail_feed.image2">
          <img :src="detail_feed.image2" alt="image2" />
        </el-carousel-item>
        <el-carousel-item class="dimg" v-if="detail_feed.image3">
          <img :src="detail_feed.image3" alt="image3" />
        </el-carousel-item>
      </el-carousel>
      <div class="describle">描述：</div>
      <div class="describle" style="font-size: 1rem">
        {{ detail_feed.message }}
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showConfirm"
      title="确认用户反馈"
      width="30%"
      :append-to-body="true"
      align="center"
    >
      <span>确认要处理这条反馈吗？</span>
      <div style="height: 50px"></div>
      <div class="flex" style="width: 100%; height: 50px">
        <el-button type="primary" @click="s_sure">确定</el-button>
      </div>
    </el-dialog>

    <div class="pagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalback.length"
        :page-size="14"
        v-model="now_page"
        @current-change="changepage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      token: null,
      showDetail: false,
      showConfirm: false,
      detail_feed: {},
      del_feed: {},
      totalback: [],
      backlist: [],
      now_page: 1,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getfeed();
  },
  methods: {
    getfeed() {
      axios
        .get("/api/admin/feedback/page", {
          params: {
            pageNo: 1,
            pageSize: 1000,
          },
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          this.totalback = res.data.data.records;
          this.data_page();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    data_page() {
      this.now_page = 1;
      let n = Math.ceil(this.totalback.length / 14);
      this.pagelist = Array.from({ length: n }, (v, k) => k + 1);
      this.changepage();
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.backlist = this.totalback.slice((page - 1) * 14, page * 14);
    },
    detailback(item) {
      this.detail_feed = item;
      this.showDetail = true;
    },
    sure_back(item) {
      this.del_feed = item;
      this.showConfirm = true;
    },
    s_sure() {
      axios
        .put(`/api/admin/feedback/confirm/${this.del_feed.id}`, null, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "确认成功",
              type: "success",
            });
            this.del_feed.status = 1;
            this.showConfirm = false;
          }
        })
        .catch((err) => {
          this.$message.error("确认失败");
        });
    },
    s_cancel() {
      this.showConfirm = false;
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Feedback {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  font-size: 25px;
  background-color: rgb(255, 255, 255);
}
.feed {
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}
.Feeddetail .el-carousel {
  height: 35vh !important;
  background-color: #ffffff;
}
.dimg {
  display: flex;
  justify-content: center;
}
.dimg img {
  height: 100%;
}
.detialbody {
  width: 100%;
  height: 100%;
  background-color: #e6e5e5;
}
.userfeed {
  display: flex;
  align-items: center;
  height: 90px;
  border-bottom: 1px solid #e6e5e5;
}
.description,
.time {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: darkgrey;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.describle {
  width: 100%;
  font-size: 1.5rem;
  box-sizing: border-box;
  color: #5e5c5c;
  padding: 10px;
}
</style>
