<template>
  <div class="ava">
    <div class="title">
      <div class="sys1"></div>
      <div class="sys2">
        <el-select
          v-model="sel"
          placeholder="请选择"
          style="width: 150px; height: 30px; font-size: 15px"
          @change="selectcla"
          size="small"
        >
          <el-option
            v-for="(item, index) in clalist"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          style="margin-left: 15px"
          type="primary"
          size="small"
          @click="add()"
        >
          新增头像
          <i class="el-icon-upload el-icon--right"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-delete"
          @click="delCategory()"
          style="background-color: red"
        >
          批量删除
        </el-button>
      </div>
    </div>

    <div class="title1">
      <div class="title2">
        <!-- 使用el-table展示数据 -->
        <el-table
          :data="avalist"
          style="width: 100%"
          height="100%"
          border
          @selection-change="seldel"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="图片" width="180" align="center">
            <template slot-scope="scope">
              <img
                :src="scope.row.image"
                alt=""
                style="width: 50px; height: auto"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="创建时间"
            width="180"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="修改时间"
            width="180"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createUser"
            label="创建者"
            width="140"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="updateUser"
            label="修改者"
            width="140"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="mod(scope.row)">
                修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="pager js">
      <el-pagination
        layout="prev, pager, next"
        :total="totalava.length"
        :page-size="11"
        @current-change="changepage"
      ></el-pagination>
    </div>

    <el-dialog
      title="添加头像"
      :visible.sync="add_dis"
      center
      :append-to-body="true"
      width="30%"
    >
      <el-form style="height: 60vh; overflow-y: scroll">
        <el-form-item label="名称">
          <el-input v-model="a_name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select
            v-model="add_sel"
            placeholder="请选择分类"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in clalist"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="头像图片">
          <el-image
            :src="selstoreIge"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="handleFileChange"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取图片
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
      </el-form>

      <div style="width: 100%; display: flex; justify-content: center">
        <el-button type="primary" @click="add_sure()">确定添加</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改头像"
      :visible.sync="mod_dis"
      center
      :append-to-body="true"
      width="30%"
    >
      <el-form ref="addForm">
        <el-form-item label="名称">
          <el-input v-model="m_name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select
            v-model="m_sel"
            placeholder="请选择分类"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in clalist"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="头像图片">
          <el-image
            :src="m_img"
            fit="contain"
            style="width: 250px; height: 250px"
          >
          </el-image>
          <el-upload
            class="upload-demo"
            ref="fileUpload"
            :auto-upload="false"
            :on-change="mhandleFileChange"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取图片
            </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item
          style="width: 100%; display: flex; justify-content: center"
        >
          <el-button type="primary" @click="mod_sure()">确定修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="del_dis"
      width="25%"
      style="height: 300vh"
      :append-to-body="true"
    >
      <div style="display: flex; justify-content: center; font-size: 1.5rem">
        确定要删除选中项吗
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 50px;
        "
      >
        <el-button type="primary" @click="del_sure()"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Vue, { del } from "vue";
export default Vue.extend({
  components: {},
  data() {
    return {
      now_page: 1,
      token: "",
      add_dis: false,
      mod_dis: false,
      del_dis: false,
      clalist: [],
      totalava: [],
      avalist: [],
      sel: 1,
      a_name: "",
      add_sel: "",
      selstoreIge: "",
      m_sel: "",
      m_id: "",
      m_name: "",
      m_img: "",
      checkall: false,
      checkstatus: false,
      ids: [],
      seldelist: [],
    };
  },
  computed: {},
  created() {
    this.token = localStorage.getItem("token");
    this.getavalist();
  },
  methods: {
    async filePut(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },
    getavalist() {
      let th = this;
      let config = {
        params: {
          pageNo: 1,
          pageSize: 100,
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/avatar/list", config).then((res) => {
        if (res.data.code == 1) {
          console.log(res);
          res.data.data.forEach((item) => {
            th.clalist.push(item);
          });
          th.sel = th.clalist[0].id;
          th.add_sel = th.clalist[0].id;
          th.getava(th.sel);
        }
      });
    },
    getava(id) {
      this.avalist = [];
      this.now_page = 1;
      let th = this;
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios
        .get(`/api/admin/avatar/list/avatarClassification/${id}`, config)
        .then((res) => {
          if (res.data.code == 1) {
            this.totalava = res.data.data;
            this.sel = id;
            this.avalist = this.totalava.slice(
              (this.now_page - 1) * 11,
              this.now_page * 11
            );
          }
        });
    },
    selectcla() {
      console.log(this.sel);
      this.getava(this.sel);
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.avalist = this.totalava.slice((page - 1) * 11, page * 11);
    },
    async handleFileChange(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.selstoreIge = img.data;
    },
    async mhandleFileChange(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.m_img = img.data;
    },
    add() {
      this.add_dis = true;
    },
    add_sure() {
      let th = this;
      axios
        .post(
          "/api/admin/avatar",
          {
            id: 0,
            name: this.a_name,
            image: this.selstoreIge,
            avatarClassificationId: this.add_sel,
          },
          {
            headers: {
              token: this.token, //请求头是用token
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "发布成功",
              type: "success",
            });
            th.getava(th.sel);
            th.a_name = "";
            th.selstoreIge = "";
            th.add_sel = th.clalist[0].id;
          } else {
            this.$message.error("发布失败");
          }
        });
      this.add_dis = false;
    },
    mod(item) {
      this.m_id = item.id;
      this.m_name = item.name;
      this.m_sel = item.avatarClassificationId;
      this.m_img = item.image;
      this.mod_dis = true;
    },
    mod_sure() {
      let th = this;
      axios
        .put(
          "/api/admin/avatar/list",
          {
            id: this.m_id,
            name: this.m_name,
            image: this.m_img,
            avatarClassificationId: this.m_sel,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            th.getava(th.sel);
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message.error("修改失败");
          }
        });
      this.mod_dis = false;
    },
    seldel(e) {
      this.seldelist = e;
    },
    del() {
      if (this.seldelist.length <= 0) {
        this.$message.error("未选择任何选项");
      } else {
        this.del_dis = true;
      }
    },
    del_sure() {
      this.ids = [];
      this.seldelist.forEach((item) => {
        this.ids.push(item.id);
      });
      let ids = this.ids; // 假设这是一个包含ID的数组
      let idStr = ids.join(","); // 将数组转换为逗号分隔的字符串
      axios
        .delete(`/api/admin/avatar/list?avatarIds=${idStr}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getava(this.clalist[0].id, this.clalist[0].name);
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
      this.del_dis = false;
    },

    selall() {
      this.checkall = !this.checkall; // 简化toggle的写法
      if (this.checkall) {
        this.checkstatus = true;
        this.ids = this.avalist.map((item) => item.id); // 使用map函数快速构建id数组
      } else {
        this.checkstatus = false;
        this.ids = [];
      }
      console.log(this.ids);
    },
    getdel(id) {
      let toid = id;
      if (this.ids.indexOf(toid) === -1) {
        this.ids.push(toid);
      } else {
        let index = this.ids.indexOf(toid);
        if (index > -1) {
          this.ids.splice(index, 1);
        }
      }
      console.log(this.ids);
      if (this.ids.length == this.avalist.length) {
        this.checkall = true;
      } else {
        this.checkall = false;
      }
    },
  },
  watch: {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.al {
  display: flex;
  align-items: center;
}
.ava {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.title {
  width: 100%;
  height: 10%;
}
.sys1 {
  width: 100%;
  height: 35%;
}
.sys2 {
  box-sizing: border-box;
  padding-left: 65%;
  widows: 100%;
  height: 50%;
}
.title1 {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
}
.title2 {
  width: 90%;
  height: 100%;
}

.pager {
  width: 100%;
  height: 10%;
}
::-webkit-scrollbar {
  display: none;
}
</style>
