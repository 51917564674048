<template>
  <div class="avacla">
    <div class="title">
      <div class="sys1"></div>
      <div class="sys2">
        <el-button type="primary" size="mini" @click="add()">
          新增分类<i class="el-icon-upload el-icon--right"></i>
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-delete"
          @click="del()"
          style="background-color: red"
        >
          批量删除
        </el-button>
      </div>
    </div>
    <div class="title1">
      <div class="title2">
        <el-table
          :data="avalist"
          style="width: 100%"
          height="100%"
          ref="multipleTable"
          border="true"
          @selection-change="selDel"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="name"
            label="分类名"
            width="180"
          ></el-table-column>

          <el-table-column
            prop="sort"
            label="排序"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="pay"
            label="花费积分"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                :type="scope.row.status == 1 ? 'primary' : 'danger'"
                plain
              >
                {{ scope.row.status == 1 ? "启用" : "禁用" }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            label="描述"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="最后操作时间"
          ></el-table-column>
          <el-table-column
            prop="createUser"
            label="创建者"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="updateUser"
            label="修改者"
            width="100"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="mod(scope.row)">
                修改
              </el-button>
              <el-button
                size="mini"
                :type="scope.row.status !== 1 ? 'success' : 'danger'"
                @click="chnageStatus(scope.row)"
              >
                {{ scope.row.status !== 1 ? "启用" : "禁用" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      title="添加新分类"
      :visible.sync="add_dis"
      center
      :append-to-body="true"
      width="30%"
    >
      <el-form>
        <el-form-item label="名称">
          <el-input v-model="a_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="a_sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="花费积分">
          <el-input v-model="a_pay" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            maxlength="300"
            show-word-limit
            v-model="a_dec"
            autocomplete="off"
            size="medium"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add_cel()">取 消</el-button>
        <el-button type="primary" @click="add_sure()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改分类"
      :visible.sync="mod_dis"
      center
      :append-to-body="true"
      width="30%"
    >
      <el-form>
        <el-form-item label="名称">
          <el-input v-model="m_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="m_sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="积分">
          <el-input v-model="m_pay" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            maxlength="300"
            show-word-limit
            v-model="m_dec"
            autocomplete="off"
            size="medium"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="mod_cel()">取 消</el-button>
        <el-button type="primary" @click="mod_sure()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="del_dis"
      width="20%"
      center
      :append-to-body="true"
    >
      <span
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        确定要删除选中项吗
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del_sure()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      avalist: [],
      showmsg: "none",
      tipmsg: "",
      sh_dis: "none",
      add_dis: false,
      mod_dis: false,
      a_name: "",
      a_sort: "",
      a_dec: "",
      a_pay: 0,
      m_id: "",
      m_name: "",
      m_sort: "",
      m_dec: "",
      m_status: "",
      m_pay: 0,
      delobject: [],
      ids: [],
      del_dis: false,
      checkall: false,
      checkstatus: false,
    };
  },
  computed: {},
  created() {
    this.token = localStorage.getItem("token");
    this.getava();
  },
  methods: {
    getava() {
      let th = this;
      this.avalist = [];
      let config = {
        params: {
          pageNo: 1,
          pageSize: 100,
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/avatar-classification/page", config).then((res) => {
        if (res.data.code) {
          this.avalist = res.data.data.records;
        }
        console.log(this.avalist);
      });
    },

    add() {
      this.add_dis = true;
    },
    add_sure() {
      axios
        .post(
          "/api/admin/avatar-classification",
          {
            name: this.a_name,
            description: this.a_dec,
            sort: this.a_sort,
            pay: this.a_pay,
          },
          {
            headers: {
              token: this.token, //请求头是用token
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getava();
            this.a_name = "";
            this.a_sort = "";
            this.a_dec = "";
            this.a_pay = 0;
          }
        });
      this.add_dis = false;
    },
    add_cel() {
      this.add_dis = false;
      this.a_name = "";
      this.a_sort = "";
      this.a_dec = "";
    },
    mod(item) {
      this.m_id = item.id;
      this.m_name = item.name;
      this.m_sort = item.sort;
      this.m_dec = item.description;
      this.m_status = item.status;
      this.m_pay = item.pay;
      this.mod_dis = true;
    },
    mod_sure() {
      this.mod_dis = false;
      axios
        .put(
          "/api/admin/avatar-classification",
          {
            id: this.m_id,
            name: this.m_name,
            description: this.m_dec,
            sort: this.m_sort,
            status: this.m_status,
            pay: this.m_pay,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.getava();
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message.error("修改失败");
          }
        });
    },
    mod_cel() {
      this.mod_dis = false;
    },
    selDel(e) {
      this.delobject = e;
    },
    del() {
      if (this.delobject.length <= 0) {
        this.$message.error("未选择任何项");
      } else {
        this.del_dis = true;
      }
    },
    del_sure() {
      this.ids = [];
      this.delobject.forEach((item) => {
        this.ids.push(item.id);
      });
      let ids = this.ids; // 假设这是一个包含ID的数组
      let idStr = ids.join(","); // 将数组转换为逗号分隔的字符串
      axios
        .delete(`/api/admin/avatar-classification?ids=${idStr}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getava();
          } else {
            this.$message.error("删除失败" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
      this.del_dis = false;
    },
    del_cel() {
      this.del_dis = false;
    },

    chnageStatus(item) {
      let newStatus = item.status ? 0 : 1; // 切换状态
      axios({
        method: "post",
        url: `/api/admin/avatar-classification/status/${newStatus}`,
        params: {
          id: item.id,
        },
        headers: {
          "Content-Type": "application/json",
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: newStatus ? "启用成功" : "禁用成功",
              type: "success",
            });
            item.status = newStatus; // 更新前端显示状态
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          this.$message.error("操作失败");
        });
    },
  },
  watch: {},
});
</script>

<style scoped>
.avacla {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.title {
  width: 100%;
  height: 10%;
}
.sys1 {
  width: 100%;
  height: 50%;
}
.sys2 {
  box-sizing: border-box;
  padding-left: 75%;
  width: 100%;
  height: 50%;
}
.title1 {
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title2 {
  width: 90%;
  height: 650px;
}
::-webkit-scrollbar {
  display: none;
}
</style>
