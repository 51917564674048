<template>
  <div class="store_cla">
    <div class="header al">
      <div>
        <el-button type="primary" size="mini" @click="add()">
          <i class="el-icon-upload el-icon--right"></i>
          新增分类
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-delete"
          size="mini"
          style="background-color: red"
          @click="del()"
        >
          批量删除
        </el-button>
      </div>
    </div>
    <div class="body flex">
      <div class="body1">
        <el-table
          :data="clalist"
          style="width: 100%"
          height="100%"
          border="true"
          @selection-change="selDel"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                :type="!scope.row.status ? 'danger' : 'primary'"
                size="mini"
                plain
              >
                {{ !scope.row.status ? "禁用" : "启用" }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createUser"
            label="创建者"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="updateUser"
            label="更新者"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="mod(scope.row)">
                修改
              </el-button>
              <el-button
                size="mini"
                :type="scope.row.status !== 1 ? 'success' : 'danger'"
                @click="chstatus(scope.row, scope.row.status)"
              >
                {{ scope.row.status !== 1 ? "启用" : "禁用" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="footer flex">
      <el-pagination
        layout="prev, pager, next"
        :total="totalcal.length"
        :page-size="14"
        @current-change="changepage"
      ></el-pagination>
    </div>

    <el-dialog
      title="添加分类"
      width="30%"
      :visible.sync="add_dis"
      :append-to-body="true"
      :modal="true"
      :center="true"
    >
      <div>
        <el-form>
          <el-form-item label="分类名称">
            <el-input v-model="addcla.name"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="addcla.sort"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="flex">
        <div>
          <el-button type="primary" @click="add_sure()">确定添加</el-button>
        </div>
      </span>
    </el-dialog>

    <el-dialog
      title="修改分类"
      width="30%"
      :visible.sync="mod_dis"
      :append-to-body="true"
      :modal="true"
      :center="true"
    >
      <div>
        <el-form>
          <el-form-item label="分类名称">
            <el-input v-model="modcla.name"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="modcla.sort"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="flex">
        <div>
          <el-button type="primary" @click="mod_sure()">确定修改</el-button>
        </div>
      </span>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="del_dis"
      width="20%"
      center
      :append-to-body="true"
    >
      <span
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        >确定要删除选中项吗</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del_sure()"> 确定删除 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import "swiper/css/swiper.min.css";
import axios from "axios";

export default Vue.extend({
  data() {
    return {
      token: "",
      add_dis: false,
      mod_dis: false,
      del_dis: false,
      now_page: 0,
      addcla: {
        name: "",
        sort: "",
      },
      modcla: {
        id: "",
        name: "",
        sort: "",
      },
      clalist: [],
      totalcal: [],
      seldellist: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getcla();
  },
  methods: {
    getcla() {
      let th = this;
      this.totalcal = [];
      let config = {
        params: {
          pageNo: 1,
          pageSize: 1000,
        },
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/store-classification/page", config).then((res) => {
        if (res.data.code == 1) {
          console.log(res.data.data.records);
          th.now_page = 1;
          let page = th.now_page;
          th.totalcal = res.data.data.records;
          th.clalist = this.totalcal.slice((page - 1) * 14, page * 14);
        }
      });
    },
    add() {
      this.add_dis = true;
    },
    add_sure() {
      if (this.addcla.name == "") {
        this.$message.error("分类名称不能为空");
      } else {
        if (this.addcla.sort == "") {
          this.$message.error("分类排序不能为空");
        } else {
          axios
            .post(
              "/api/admin/store-classification",
              {
                name: this.addcla.name,
                sort: this.addcla.sort,
              },
              {
                headers: {
                  token: this.token,
                },
              }
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.getcla();
              } else {
                this.$message.error("添加失败");
              }
            });
          this.add_dis = false;
          this.addcla.name = "";
          this.addcla.sort = "";
        }
      }
    },
    mod(item) {
      this.modcla.id = item.id;
      this.modcla.name = item.name;
      this.modcla.sort = item.sort;
      this.mod_dis = true;
    },
    mod_sure() {
      let th = this;
      axios
        .put(
          "/api/admin/store-classification",
          {
            id: this.modcla.id,
            name: this.modcla.name,
            sort: this.modcla.sort,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            th.getcla();
          } else {
            this.$message.error("修改失败，请先禁用分类");
          }
        });
      this.modcla.id = "";
      this.modcla.name = "";
      this.modcla.sort = "";
      this.mod_dis = false;
    },
    chstatus(item, status) {
      let newStatus = status ? 0 : 1; // 切换状态
      console.log(newStatus);
      axios({
        method: "post",
        url: `/api/admin/store-classification/status/${newStatus}`,
        params: {
          id: item.id,
        },
        headers: {
          "Content-Type": "application/json",
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data.code == 1) {
            newStatus;
            this.$message({
              message: newStatus ? "启用成功" : "禁用成功",
              type: "success",
            });
            item.status = newStatus; // 更新前端显示状态
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          this.$message.error("操作失败");
        });
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.clalist = this.totalcal.slice((page - 1) * 14, page * 14);
    },
    selDel(e) {
      this.seldellist = e;
    },
    del() {
      if (this.seldellist.length <= 0) {
        this.$message.error("未选中任何项");
      } else {
        this.del_dis = true;
      }
    },
    del_sure() {
      let ids = []; // 假设这是一个包含ID的数组
      this.seldellist.forEach((item) => {
        ids.push(item.id);
      });
      console.log(ids);
      let idStr = ids.join(","); // 将数组转换为逗号分隔的字符串
      axios
        .delete(`/api/admin/store-classification?ids=${idStr}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getcla();
          } else {
            this.$message.error("删除失败" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
      this.del_dis = false;
    },
  },
  mounted() {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-content: center;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.store_cla {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.header {
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  padding-left: 75%;
}
.body {
  widows: 100%;
  height: 80%;
}
.body1 {
  width: 90%;
  height: 100%;
}
.footer {
  width: 100%;
  height: 8%;
}
</style>
